import { useLocation } from "react-router-dom";

export const importAllFrom = (route) => route.keys().map(route);

export const redirectToCiDi = () => window.open(process.env.REACT_APP_URL_LOGIN_CIDI, '_self');

export const registrate = () => window.open(process.env.REACT_APP_REGISTRATE, '_self');

export const useQuery = () => new URLSearchParams(useLocation().search);

export const isNative = () => {
    return window.ReactNativeWebView !== undefined;
};
